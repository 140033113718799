<template>
  <div>
    <div>
      <div class="project-search">
        <div class="flex search-text align-center">
          <div>
            <span>名称：</span>
            <input v-model="pageInfo.labName" placeholder="实验室名称" class="searchInput" />
          </div>
          <div class="marginLeft10">
            <span>状态：</span>
            <el-select v-model="pageInfo.state" placeholder="请选择状态" class="searchSelect" size="large">
              <el-option v-for="(item,index) in stateArray" :key="index" :label="item" :value="index" />
            </el-select>
          </div>
          <div class="marginLeft10">
            <span>日期：</span>
            <el-date-picker v-model="pageInfo.reservationTime" type="date" placeholder="预约日期" size="large" value-format="YYYY-MM-DD" />
          </div>
          <div class="marginLeft10">
            <span>学院名称：</span>
            <el-select v-model="pageInfo.collegeId" filterable placeholder="请选择一个学院" size="large">
              <el-option v-for="item in $root.collegeList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </div>
          <button class="searchBtn" @click="search">搜索</button>
          <button class="resetBtn" @click="reset(null)">重置</button>
        </div>
      </div>
      <div class="project-table" style="margin-top: 20px">
        <div class="flex align-center justify-between paddingBTM20">
          <div class="fontSize20gay">实验室预约列表</div>
          <div class="flex">
            <button class="stateBtn" @click="showCheck(1)">批量通过</button>
            <button class="stateBtn marginLeft10" @click="showCheck(2)">批量不通过</button>
            <button class="stateBtn marginLR10" @click="showCheck(3)">批量退回</button>
            <div>
              <el-upload class="upload-demo" :action="'/'+$root.projectName + '/reservation/import?Authorization=Bearer '+token" list-type="picture" accept=".xls,.xlsx" :show-file-list="false" :on-progress="beforxfg" :on-success="fileVb">
                <button class="exportBtn">导入</button>
              </el-upload>
            </div>
            <button class="exportBtn marginLR10" @click="showDown('reservation')">下载模板</button>
            <button class="exportBtn marginLR10" @click="exportFile">导出预约</button>
            <button class="addBtn marginLeft10" @click="showEdit('')"> + 新增</button>
          </div>
        </div>
        <el-table :data="tableData" border row-key="id" @selection-change="handleSelectionChange"
                  :header-cell-style="{'text-align':'center','color':'#999999','padding':'16px 0','background-color':'#EBEBEB'}"
                  :cell-style="{'text-align':'center'}">
          <el-table-column type="selection" width="55" />
          <el-table-column prop="name" label="实验室名称">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              {{scope.row.laboratory?scope.row.laboratory.name:''}}
            </template>
          </el-table-column>
          <el-table-column label="申请人">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              {{scope.row.user?scope.row.user.name:''}}
            </template>
          </el-table-column>
          <el-table-column label="身份">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              {{scope.row.type==1?'教师':'学生'}}
            </template>
          </el-table-column>
          <el-table-column label="单位/院校/专业/班级">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <span v-if="scope.row.user">
                {{scope.row.type==1?(scope.row.user.department || ''):((scope.row.user.school || '')+' '+(scope.row.user.school || '')+' '+(scope.row.user.clasz || '')+' ')}}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="reservationTime" label="预约日期" />
          <el-table-column prop="className" label="授课班级" />
          <el-table-column prop="studentNum" label="学生人数" />
          <el-table-column prop="studentPhone" label="联系电话" />
          <el-table-column label="用途" width="200">
            <template #default="scope">
              <div style="width: 180px;cursor: auto;text-align: left !important;" class="text-out-hide" :title="scope.row.use">{{scope.row.use}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="time" label="申请时间" />
          <el-table-column label="当前状态">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <span class="fontSize12" :class="'stateCss'+scope.row.state">●</span>
              <span class="marginLeft5">{{stateArray[scope.row.state]}}</span>
            </template>
          </el-table-column>
          <el-table-column label="备注">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              {{ scope.row.remarks }}
            </template>
          </el-table-column>
          <el-table-column label="审核人">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <span class="marginLeft5">{{scope.row.examineUser?scope.row.examineUser.name:''}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="examineTime" label="审核时间" />
          <el-table-column fixed="right" label="操作" width="130">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <div style="display: flex;justify-content: center;">
                <el-button v-if="scope.row.state===0 || scope.row.state===2"
                           link type="primary" style="color: #3769B9;height: 17px;line-height: 17px;"
                           @click="showEdit(scope.row.id)">编辑</el-button>
                <div v-else class="fontSize14gay99" style="height: 17px;line-height: 17px;">编辑</div>
<!--                通过且未签到 或 有附件 -->
                <el-dropdown v-if="(scope.row.state === 1 && !(scope?.row?.sign)) || scope?.row?.annex" style="margin-left: 10px;cursor: pointer;">
                  <span class="el-dropdown-link" style="color: #3769b9;">
                    更多
                    <el-icon class="el-icon--right"><arrow-down /></el-icon>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item v-if="scope.row.state === 1 && !(scope?.row?.sign)" @click="appointmentCheckIn(scope.row.id, true)">实到</el-dropdown-item>
                      <el-dropdown-item v-if="scope.row.state === 1 && !(scope?.row?.sign)" @click="appointmentCheckIn(scope.row.id, false)">未到</el-dropdown-item>
                      <el-dropdown-item v-if="scope?.row?.annex" @click="downLoadAnnex(scope.row)">查看附件</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="审核" width="150">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <div v-if="scope.row.state===0">
                <el-button link type="primary" style="color: #3769B9" @click="checkOne(scope.row.id,1)">通过</el-button>
                <el-button link type="primary" style="color: #3769B9" @click="checkOne(scope.row.id,2)">不通过</el-button>
              </div>
              <div v-else-if="scope.row.state===1">
                <el-button link type="primary" style="color: #3769B9" @click="checkOne(scope.row.id,3)">退回</el-button>
              </div>
              <div v-else class="fontSize14gay99">无操作</div>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-center paddingTD20">
          <!--   分页     -->
          <el-pagination
              :currentPage="pageInfo.page"
              :page-size="pageInfo.limit"
              :page-sizes="[10, 20, 50, 100]"
              :small="true"
              :disabled="false"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageInfo.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <!-- 操作窗口   -->
    <el-dialog v-model="dialogFormVisible" title="操作信息" style="padding: 10px">
      <el-form>
        <el-form-item label="备注" label-width="80">
          <el-input v-model="formStateData.remarks" autocomplete="off" placeholder="请输入备注信息" />
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="subStateData">提交</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {getAppoinmentPage, checkAppointment, downFile, exportFile, reservationSignCheck} from "@/api/api";
import {ElLoading} from "element-plus";
import { read, utils } from 'xlsx';
import {excelTemp} from "@/utils/common";
import {projectName} from "@/utils/config";
import {last} from 'lodash'

export default {
  name: "roomList",
  data(){
    return{
      token:localStorage.getItem('token'),
      stateArray:this.$root.stateList,
      tableData:[],
      dataInfo:[],
      Info:null,
      dialogFormVisible:false,
      checkIds:[],
      pageInfo:{
        limit:10,
        labName:'',
        state:'',
        reservationTime:'',
        collegeId:'',
        page:1,
        total:0
      },
      exportIds:'',
      formStateData:{reservationIds:[],state:'',remarks:''}
    }
  },
  mounted() {
    this.getList();
  },
  methods:{
    downLoadAnnex({ annex }){
      const file = document.createElement("a");
      file.style.display = "none";
      file.href = projectName + annex;
      file.download = decodeURI(last((annex||'/附件').split('/')));
      document.body.appendChild(file);
      file.click();
      document.body.removeChild(file);
    },
    appointmentCheckIn(id, status){
      reservationSignCheck({ reservationId: id, success: status }).then(res => {
        if (res.success) {
          this.$message.success('提交成功')
          this.getList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    reset(){
      this.pageInfo.labName='';
      this.pageInfo.state='';
      this.pageInfo.reservationTime='';
      this.pageInfo.collegeId='';
    },
    search(){
      this.pageInfo.limit=10;
      this.pageInfo.page=1;
      this.getList();
    },
    getList(){
      getAppoinmentPage(this.pageInfo).then(res=>{
        this.pageInfo.total=parseInt(res.data.total);
        this.tableData=res.data.records;
      })
    },
    showEdit(id){
      this.$root.useRouter.push({path:"/room/appoinmentEdit",query:{id:id}});
    },
    handleSelectionChange(e){
      let ids=[];
      for(let i in e){
        ids.push(e[i].id);
      }
      this.checkIds=ids;
    },
    closeDiv(){
      this.dialogFormVisible=false;
    },
    checkOne(id,state){
      this.dialogFormVisible=true;
      let data={reservationIds:[id],state:state,remarks:''}
      this.formStateData=data;
    },
    showCheck(state){
      if(this.checkIds.length<1){this.$root.ElMessage({type:'warning',message:'请至少选择一条记录'});return false;}
      this.dialogFormVisible=true;
      let data={reservationIds:this.checkIds,state:state,remarks:''}
      this.formStateData=data;
    },
    subStateData(){
      checkAppointment(this.formStateData).then((res)=>{
        if(res.success){
          this.dialogFormVisible=false;
          this.$root.ElMessage({type:'success',message:res.message});
          this.getList();
        }else{
          this.$root.ElMessage.error(res.message);
        }
      })
    },
    handleSizeChange(e){//修改每页显示条数
      this.pageInfo.limit=e;
      this.pageInfo.page=1;
      this.getList();
    },
    handleCurrentChange(e){
      this.pageInfo.page=e;
      this.getList();
    },
    showDown(name){
      downFile(name);
    },
    exportFile(){
      exportFile('/reservation/export?Authorization=Bearer '+localStorage.getItem('token')+'&labName='+this.pageInfo.labName+'&reservationTime='+this.pageInfo.reservationTime+'&state='+this.pageInfo.state);
    },
    beforxfg(){
      this.loading = this.$root.loadingOpen();
    },
    fileVb(res){
      if(res.success){
        this.$root.ElMessage({type:'success',message:res.message});
        this.getList();
      }else{
        this.$root.ElMessage.error(res.message);
      }
      this.loading.close();
    }
  }
}
</script>

<style lang="scss" scoped>
.data-title{font-size: 15px;color: #000000}
.data-content{
  font-size: 13px;display: flex;justify-content: space-between;margin-top: 15px;
}
</style>
